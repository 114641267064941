/* 
 * @Author: 曹俊杰 
 * @Date: 2023-01-08 11:09:43
 * @Module: 印章管理
 */
 <template>
  <div class="SealManagement border">
    <div class="item" v-for="item in list" :key="item.uuid">
      <div class="top">
        <img :src="$oss+item.seal.sealImageUrl" alt="" srcset="">
      </div>
      <div class="bottom">
        <div class="cell">
          <div class="label">名称</div>
          <div class="value">{{item.seal.sealName}}</div>
        </div>
        <div class="cell">
          <div class="label">生效日期</div>
          <div class="value">{{item.effectiveTime|timeFormat}}</div>
        </div>
        <div class="cell">
          <div class="label">失效日期</div>
          <div class="value">{{item.expireTime|timeFormat}}</div>
        </div>
      </div>
    </div>
    <el-empty description="暂无数据" v-if="list.length===0" style="margin:0 auto;"></el-empty>
  </div>
</template>
 <script>
export default {
  components: {},
  data () {
    return {
      list: []
    };
  },
  mounted () {
    this.getSealAuthMineList()
  },
  methods: {
    async getSealAuthMineList () {
      const { data, status } = await this.$api.getSealAuthMineList()
      if (status === 200) {
        this.list = data
      }
    },
  },
};
 </script>
 <style lang='scss' scoped>
.border {
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  background-color: #fff;
}
.SealManagement {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;

  .item {
    width: calc((100% - (12px * 4)) / 5);
    margin-right: 12px;
    height: 270px;
    margin-bottom: 12px;
    border: 1px solid #eeeeee;
    border-radius: 4px;
    &:nth-child(5n) {
      margin-right: 0px;
    }
    &:nth-last-child(-n + 5) {
      margin-bottom: 0px;
    }
    .top {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 160px;
      border-bottom: 1px solid #eeeeee;
      img {
        width: 100px;
        height: 100px;
      }
    }
    .bottom {
      height: 110px;
      .cell {
        display: flex;
        font-family: "Source Han Sans CN";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #666666;
        padding: 0 20px;
        margin-top: 14px;
        .label {
          width: 48px;
        }
        .value {
          margin-left: 16px;
          color: #262626;
        }
      }
    }
  }
}
</style>